import ContentNews from 'components/atoms/side_menu/ContentNews';
import ContentNotify from 'components/atoms/side_menu/ContentNotify';
import { useRouter } from 'next/router';
import { MdClose } from 'react-icons/md';
import styled, { css } from 'styled-components';
import { fontSize, mediaDown } from 'styles/mixins';
import { backgroundColors, colors, grayScales, zIndexes } from 'styles/variables';

type ItemsType = '' | 'notify' | 'news' | 'report';

type SideMenuContentType = {
  type: ItemsType;
  onClick: () => void;
  list: object[];
};

const SideMenuContentStyled = styled.div.attrs((props: { type: ItemsType }) => ({
  type: props.type,
}))`
  .side-menu-content {
    position: fixed;
    top: 108px;
    left: 140px;
    width: 330px;
    margin-left: 21px;
    color: ${colors.white};
    z-index: ${zIndexes.sideMenu};

    ${mediaDown(
    'desktop',
    css`
        bottom: 64px;
        left: auto;
        margin-bottom: 20px;
        margin-left: 0;
        position: absolute;
        right: 0;
        top: auto;
      `
  )}

    &::after {
      ${fontSize(16)};
      position: fixed;
      left: 148px;
      top: ${(props) => {
    switch (props.type) {
    case 'notify':
      return '135px';
    case 'news':
      return '195px';
    case 'report':
      return '265px';
    default:
      return '';
    }
  }};
      content: '▲';
      transform: rotate(-90deg);
      color: ${backgroundColors.sideMenu};

      ${(props) => mediaDown(
    'desktop',
    css`
    bottom: 76px;
    left: auto;
    right: ${() => {
    switch (props.type) {
    case 'notify':
      return '118px';
    case 'news':
      return '54px';
    case 'report':
      return '30px';
    default:
      return '';
    }
  }}
    top: auto;
    transform: rotate(180deg);
  `
  )}
    }

    &__header {
      border-radius: 5px 5px 0 0;
      background-color: ${backgroundColors.sideMenu};
      ${fontSize(14)}
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 15px 10px 15px;
      border-bottom: solid 1px ${grayScales.gs600};

      span {
        svg {
          cursor: pointer;
          ${fontSize(24)}

          path {
            stroke: ${colors.white};
          }
        }
      }
    }

    &__body {
      max-height: 500px;
      /* overflow-y: scroll; */
    }

    &__footer {
      border-radius: 0 0 5px 5px;
      background-color: ${backgroundColors.sideMenu};
      ${fontSize(12)}
      display: flex;
      justify-content: center;
      padding: 10px 15px 20px 15px;

      div {
        cursor: pointer;
      }
    }
  }
`;

// この辺コンポーネント分割してリファクタしたほうがいいけど一人じゃ手が回らん
const SideMenuContent = (props: SideMenuContentType) => {
  const router = useRouter(),
    getText = (type: ItemsType) => {
      switch (type) {
      case 'notify':
        return '新着メッセージ';
      case 'news':
        return '最新情報';
      case 'report':
        return '素材利用報告';
      default:
        return '';
      }
    },
    getUrl = (type: ItemsType) => {
      switch (type) {
      case 'notify':
        return '/message';
      case 'news':
        return '/article/news/list';
      case 'report':
        return '/material/report';
      default:
        return '';
      }
    };

  if (props.type === '') {
    return <></>;
  }

  return (
    <SideMenuContentStyled type={props.type}>
      <div className='side-menu-content'>
        <div className='side-menu-content__header'>
          <span>{getText(props.type)}</span>
          <span onClick={props.onClick}>
            <MdClose />
          </span>
        </div>
        <div className='side-menu-content__body'>
          {props.list.map((item, index) => {
            if (props.type === 'notify') {
              return <ContentNotify item={item} key={index} />;
            }
            if (props.type === 'news') {
              return <ContentNews item={item} key={index} />;
            }
            return '';
          })}
        </div>
        <div className='side-menu-content__footer'>
          <div onClick={() => router.push(getUrl(props.type))}>{`${getText(props.type)}一覧へ`}</div>
        </div>
      </div>
    </SideMenuContentStyled>
  );
};

export default SideMenuContent;

export type { ItemsType };
