import SideMenuButton from 'components/atoms/side_menu/SideMenuButton';
import SideMenuLabel from 'components/atoms/side_menu/SideMenuLabel';
import { useState } from 'react';
import styled from 'styled-components';
import { colors } from 'styles/variables';

const SideMenuItemStyled = styled.span`
  position: relative;

  .unread-count {
    background-color: ${colors.danger};
    border-radius: 50%;
    color: ${colors.white};
    cursor: pointer;
    font-size: 10px;
    font-weight: 500;
    padding: 5px 7px;
    position: absolute;
    text-align: center;
    top: 15px;
  }
`;

type SideMenuItemType = {
  type: string | 'notify' | 'news' | 'report';
  onClick: () => void;
  unreadCount?: number;
};

const SideMenuItem = (props: SideMenuItemType) => {
  const [hover, setHover] = useState(false);

  return (
    <SideMenuItemStyled>
      <div className='side-menu-item' onClick={props.onClick}>
        {props.unreadCount === 0 ? '' : <span className='unread-count'>{props.unreadCount}</span>}
        <SideMenuButton setHover={setHover} type={props.type} />
        <SideMenuLabel hover={hover} type={props.type} />
      </div>
    </SideMenuItemStyled>
  );
};

export default SideMenuItem;
