import { useRouter } from 'next/router';
import styled from 'styled-components';
import { fontSize } from 'styles/mixins';
import { backgroundColors, colors, gradations, grayScales } from 'styles/variables';

const ContentNewsStyled = styled.div`
  .content-news {
    background-color: ${backgroundColors.sideMenu};
    border-bottom: solid 1px ${grayScales.gs600};
    padding: 15px;

    &:hover {
      background-color: ${backgroundColors.sideMenuSelected};
    }

    &__content {
      display: flex;
      justify-content: space-between;

      &--thumbnail {
        ${fontSize(16)}
        align-items: center;
        background-image: ${gradations.primary};
        border-radius: 5px;
        display: flex;
        font-family: Oswald, sans-serif;
        font-weight: 400;
        height: 70px;
        justify-content: center;
        width: 120px;

        img {
          height: 100%;
          width: 100%;
        }
      }

      &--text {
        display: flex;
        flex-direction: column;
        height: 70px;
        justify-content: space-between;
        text-align: left;
        width: 160px;

        &-title {
          ${fontSize(14)}
        }

        &-date {
          ${fontSize(12)}
        }
      }
    }

    &__footer {
      &--button {
        ${fontSize(14)}
        background-color: ${grayScales.gs600};
        border: none;
        border-radius: 5px;
        color: ${colors.white};
        cursor: pointer;
        margin-top: 15px;
        padding: 10px 20px;
        width: 100%;
      }
    }
  }
`;

type ContentNewsType = {
  item: any;
};

const ContentNews = (props: ContentNewsType) => {
  const router = useRouter(),
    array = props.item.date.split('(')[0].split('/');

  return (
    <ContentNewsStyled>
      <div className='content-news'>
        <div className='content-news__content'>
          <div className='content-news__content--thumbnail'>
            {(props.item.thumbnailUrl === null || props.item.thumbnailUrl === '') ? (
              [array[1], array[2]].join('/')
            ) : (
              <img src={props.item.thumbnailUrl} alt='news_thumbnail' />
            )}
          </div>
          <div className='content-news__content--text'>
            <div className='content-news__content--text-title'>{props.item.title}</div>
            <div className='content-news__content--text-date'>{props.item.date}</div>
          </div>
        </div>
        <div className='content-news__footer'>
          <button
            className='content-news__footer--button'
            onClick={() => router.push(`/article/news/list/${props.item.articleId}`)}
          >
            もっと読む
          </button>
        </div>
      </div>
    </ContentNewsStyled>
  );
};

export default ContentNews;
