import styled, { css } from 'styled-components';
import { mediaDown } from 'styles/mixins';

const SideMenuLabelStyled = styled.div`
  .side-menu-label {
    visibility: hidden;
    background-color: rgba(89, 89, 89, 0.95);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 7px 10px;
    position: absolute;
    left: -98px;
    top: 20px;
    z-index: 100;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);

    ${mediaDown(
    'desktop',
    css`
        display: none;
      `
  )}

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-left-color: rgba(89, 89, 89, 0.95);
      border-right: 0;
      margin-top: -5px;
      margin-right: -5px;
    }
  }

  .show-side-menu-label {
    visibility: visible;
  }

  .hide-side-menu-label {
    visibility: hidden;
  }
`;

type SideMenuLabelType = {
  hover: boolean;
  type: string | 'notify' | 'news' | 'report';
};

const SideMenuLabel = (props: SideMenuLabelType) => (
  <SideMenuLabelStyled>
    <div className={['side-menu-label', props.hover ? 'show-side-menu-label' : 'hide-side-menu-label'].join(' ')}>
      {props.type === 'notify' ? 'お知らせ' : ''}
      {props.type === 'news' ? '最新情報' : ''}
      {props.type === 'report' ? '素材報告' : ''}
    </div>
  </SideMenuLabelStyled>
);

export default SideMenuLabel;
