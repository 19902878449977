import SideMenuContent, { ItemsType } from 'components/shareds/side_menu/SideMenuContent';
import SideMenuItem from 'components/shareds/side_menu/SideMenuItem';
import { useCallApi } from 'hooks/useCallApi';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { mediaDown } from 'styles/mixins';
import { zIndexes } from 'styles/variables';
import { SideMenuRequest } from 'types/apiRequests';
import { SideMenuResultType } from 'types/apiResponseTypes';

const SideMenuStyled = styled.div`
    .side-menu {
      display: block;
      left: 80px;
      position: fixed;
      text-align: center;
      top: 108px;
      width: 64px;
      z-index: ${zIndexes.sideMenu};

      ${mediaDown(
    'desktop',
    css`
          bottom: 5px;
          display: flex;
          justify-content: flex-end;
          left: auto;
          right: 30px;
          top: auto;
          width: auto;
        `
  )}

      & > span {
        ${mediaDown(
    'desktop',
    css`
      flex: 0 0 64px;
    `
  )}
      }

      span:first-child .side-menu-item .side-menu-button {
        border-radius: 5px 5px 0 0;

        ${mediaDown(
    'desktop',
    css`
      border-radius: 5px 0 0 5px;
    `
  )}
      }

      span:nth-child(2) .side-menu-item .side-menu-button {
        border-radius: 0 0 5px 5px;

        ${mediaDown(
    'desktop',
    css`
      border-radius: 0 5px 5px 0;
    `
  )}
      }
    }
  `,
  SideMenu: React.FC = () => {
    const { callApi, result: content } = useCallApi<SideMenuResultType>(),
      router = useRouter(),
      [current, setCurrent] = useState<ItemsType>(''),
      loadSideMenu = async () => {
        await callApi(new SideMenuRequest);
      }

    useEffect(() => {
      // 30秒毎にメッセージを再取得する
      const intervalId = setInterval(async () => await loadSideMenu(), 30 * 1000);

      (async () => await loadSideMenu())();

      return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
      const clearCurrent = () => {
        setCurrent('');
      }

      router.events.on('routeChangeStart', clearCurrent);

      return () => {
        router.events.off('routeChangeStart', clearCurrent);
      }
    }, []);

    if (content === null) {
      return <></>;
    }

    return (
      <SideMenuStyled>
        <div className='side-menu'>
          <SideMenuItem
            type='notify'
            unreadCount={content?.notify.unreadCount}
            onClick={() => setCurrent('notify')}
          />
          <SideMenuItem
            type='news'
            unreadCount={0}
            onClick={() => setCurrent('news')}
          />
          {current !== '' && (
            <SideMenuContent
              type={current}
              onClick={() => setCurrent('')}
              list={content?.[current].list ?? []}
            />
          )}
        </div>
      </SideMenuStyled>
    );
  };

export default SideMenu;
