import { useRouter } from 'next/router';
import { BiChevronRight } from 'react-icons/bi';
import styled from 'styled-components';
import { fontSize } from 'styles/mixins';
import { backgroundColors, gradations, grayScales } from 'styles/variables';

const ContentNotifyStyled = styled.div`
  .content-notify {
    background-color: ${backgroundColors.sideMenu};
    border-bottom: solid 1px ${grayScales.gs600};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 15px;

    &:hover {
      background-color: ${backgroundColors.sideMenuSelected};
    }

    &__icon {
      ${fontSize(16)}
      align-items: center;
      background-image: ${gradations.primary};
      border-radius: 50%;
      display: flex;
      font-weight: 400;
      height: 48px;
      justify-content: center;
      text-align: center;
      width: 48px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 100%;
      text-align: left;
      width: 200px;

      &--title {
        ${fontSize(14)}
      }

      &--date {
        ${fontSize(12)}
      }
    }

    &__arrow {
      ${fontSize(24)}
      align-items: center;
      display: flex;
      width: 24px;
    }
  }
`;

type ContentNotifyType = {
  item: any;
};

const ContentNotify = (props: ContentNotifyType) => {
  const router = useRouter();

  return (
    <ContentNotifyStyled>
      <div className='content-notify' onClick={() => router.push(`/message/show/${props.item.messageId}`)}>
        <div className='content-notify__icon'>U</div>
        <div className='content-notify__content'>
          <div className='content-notify__content--title'>{props.item.title}</div>
          <div className='content-notify__content--date'>{props.item.date}</div>
        </div>
        <div className='content-notify__arrow'>
          <BiChevronRight />
        </div>
      </div>
    </ContentNotifyStyled>
  );
};

export default ContentNotify;
