import { FaEnvelope, FaListUl } from 'react-icons/fa';
import { MdMessage } from 'react-icons/md';
import styled, { css } from 'styled-components';
import { mediaDown } from 'styles/mixins';
import { backgroundColors } from 'styles/variables';

const SideMenuButtonStyled = styled.div`
  .side-menu-button {
    align-items: center;
    background-color: ${backgroundColors.sideMenu};
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 64px;
    justify-content: center;
    margin-bottom: 1px;

    ${mediaDown(
    'desktop',
    css`
        margin-bottom: 0;
        margin-right: 1px;
      `
  )}
  }
`;

type SideMenuButtonType = {
  // eslint-disable-next-line no-unused-vars
  setHover: (key1: boolean) => void;
  type: string | 'notify' | 'news' | 'report';
};

const SideMenuButton = (props: SideMenuButtonType) => (
  <SideMenuButtonStyled>
    <span className='side-menu-button' onMouseEnter={() => props.setHover(true)} onMouseLeave={() => props.setHover(false)}>
      {props.type === 'notify' ? <FaEnvelope size={20} /> : ''}
      {props.type === 'news' ? <FaListUl size={20} /> : ''}
      {props.type === 'report' ? <MdMessage size={24} /> : ''}
    </span>
  </SideMenuButtonStyled>
);

export default SideMenuButton;
