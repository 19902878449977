import LogoUuum from 'components/atoms/logo/LogoUuum';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import { fontSize, mediaDown } from 'styles/mixins';
import { colors, grayScales } from 'styles/variables';

const FooterStyled = styled.div`
    .footer {
      ${fontSize(12)}
      align-items: center;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      padding: 24px;
      width: 100%;

      ${mediaDown(
    'desktop',
    css`
          background-color: ${colors.white};
          display: block;
          padding: 48px 24px;
        `
  )}

      a {
        color: ${grayScales.gs600};
        text-decoration: none;
      }

      &__left {
        align-items: center;
        display: flex;
        justify-content: space-between;

        ${mediaDown(
    'desktop',
    css`
            display: block;
            width: 100%;
          `
  )}

        &--logo {
          ${mediaDown(
    'desktop',
    css`
              display: flex;
              justify-content: center;
              margin: 15px 0;
              width: 100%;
            `
  )}
        }

        &--contents {
          ${mediaDown(
    'desktop',
    css`
              display: flex;
              justify-content: center;
              margin: 15px 0;
              width: 100%;
            `
  )}

          span {
            margin-left: 20px;
          }
        }
      }

      &__right {
        &--copyright {
          ${mediaDown(
    'desktop',
    css`
              display: flex;
              justify-content: center;
              margin: 15px 0;
              width: 100%;
            `
  )}
        }
      }
    }
  `,
  Footer: React.FC = () => (
    <FooterStyled>
      <div className='footer'>
        <div className='footer__left'>
          <span className='footer__left--logo'>
            <LogoUuum />
          </span>
          <span className='footer__left--contents'>
            <span>
              <Link href='https://www.uuum.co.jp/privacy_policy'>プライバシーポリシー</Link>
            </span>
            <span>
              <Link href='/termofuse'>利用規約</Link>
            </span>
            <span>
              <Link href='/faq'>よくあるご質問</Link>
            </span>
            <span>
              <Link href='/contact'>お問い合わせ</Link>
            </span>
          </span>
        </div>
        <div className='footer__right'>
          <span className='footer__right--copyright'>Copyright © UUUM Co.,Ltd. All rights reserved.</span>
        </div>
      </div>
    </FooterStyled>
  );

export default Footer;
