import * as Sentry from '@sentry/nextjs';
import Layout from 'components/layouts/Layout';
import Footer from 'components/shareds/Footer';
import HeaderMenu from 'components/shareds/menu/HeaderMenu';
import SideMenu from 'components/shareds/side_menu/SideMenu';
import { getHeaderMenuState } from 'contexts/HeaderMenuContext';
import { getPageTitleState } from 'contexts/PageTitleContext';
import { useCallApi } from 'hooks/useCallApi';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { mediaDown } from 'styles/mixins';
import { backgroundColors, heights, mobileHeights } from 'styles/variables';
import { HeaderMenuRequest } from 'types/apiRequests';
import { HeaderMenuResultType } from 'types/apiResponseTypes';

const AppLayoutStyled = styled.div`
    .app-layout {
      background-color: ${backgroundColors.default};
      box-sizing: border-box;
      width: 100%;

      &__container {
        box-sizing: border-box;
        min-height: 100vh;
        padding-bottom: ${heights.footerMenu};
          ${mediaDown(
    'desktop',
    css`
                padding-bottom: ${mobileHeights.footerMenu};
              `
  )}
        position: relative;

        &--side-menu {
        }

        &--header {
        }

        &--body {
          margin: 0 auto 0;
          max-width: 1220px;
          padding: calc(${heights.headerMenu} + 40px) 40px 80px 40px;

          ${mediaDown(
    'phone',
    css`
              padding: calc(${heights.headerMenu} + 40px) 10px 80px 10px;
            `
  )}
        }

        &--footer {
          bottom: 0;
          position: absolute;
          width: 100%;
        }
      }

      @keyframes showContents {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      .show-contents {
        animation-duration: 0.8s;
        animation-name: showContents;
        animation-timing-function: ease;
      }
    }
  `,
  AppLayoutWrapper = (props: { children?: React.ReactNode }): React.ReactElement => {
    const { callApi } = useCallApi<HeaderMenuResultType>(),
      { setHeaderMenu } = getHeaderMenuState(),
      { setPageTitle } = getPageTitleState(),
      router = useRouter();

    useEffect(() => {
      (async () => {
        const response = await callApi(new HeaderMenuRequest);

        if (response.result === null) {
          return;
        }

        setHeaderMenu(response.result);

        Sentry.setUser({
          'channelId': response.result.channelId,
          'channelName': response.result.channelName,
        });

        // ざっくりなのでちゃんとやるならページ毎にタイトルを設定する
        const result = response.result.menu
          .concat(response.result.basicMenu)
          .find((item: { path: string }) => item.path.indexOf(router.pathname) > -1 && router.pathname !== '/');

        if (result !== undefined) {
          setPageTitle(result.name);
        }
      })();
    }, []);

    return (
      <AppLayoutStyled>
        <div className='app-layout'>
          <div className='app-layout__container'>
            <SideMenu />

            <header className='app-layout__container--header'>
              <HeaderMenu />
            </header>

            <main className='app-layout__container--body show-contents'>{props.children}</main>

            <footer className='app-layout__container--footer show-contents'>
              <Footer />
            </footer>
          </div>
        </div>
      </AppLayoutStyled>
    );
  },
  AppLayout = (props: { children?: React.ReactElement }) => (
    <Layout>
      <AppLayoutWrapper {...props} />
    </Layout>
  ),
  getAppLayout = (page: React.ReactElement) => (
    <AppLayout>{ page }</AppLayout>
  );

export { getAppLayout };

export default AppLayout;
